export default {
  colors: {
    pink: "#efc8df",
    blue: "#c8efeb",
    green: "#d2efc8",
    darkgrey: "#444444",
    yellow: "#fcf5c7",
    grey:"#f6f6eb",
    purple:"#ddd3ee",
    red: "#fb6f92"
  },
};
