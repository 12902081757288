import { useEffect, useState } from "react";
import styled from "styled-components";
import Countdown from "react-countdown";
import { Button, CircularProgress, Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import whitelist from "./whitelist.json";

import * as anchor from "@project-serum/anchor";

import { useWallet } from "@solana/wallet-adapter-react";
import { WalletMultiButton } from "@solana/wallet-adapter-material-ui";

import {
  CandyMachine,
  awaitTransactionSignatureConfirmation,
  getCandyMachineState,
  mintOneToken,
} from "./candy-machine";

import theme from "./theme";

const ConnectButton = styled(WalletMultiButton)`
  color: ${theme.colors.pink} !important;
  background-color: #000 !important;
  font-family: "Press Start 2P", monospace !important;
  margin-bottom: 1rem !important;
`;

const CounterText = styled.span``; // add your styles here

const MintContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`; // add your styles here

const MintButton = styled(Button)`
  color: ${theme.colors.pink} !important;
  background-color: #000 !important;
  font-family: "Press Start 2P", monospace !important;
  &:disabled {
    color: rgba(0, 0, 0, 0.4) !important;
    box-shadow: none;
    background-color: rgba(0, 0, 0, 0.2) !important;
  }
  padding: 1.5rem !important;
`; // add your styles here

export interface MintProps {
  candyMachineId: anchor.web3.PublicKey;
  config: anchor.web3.PublicKey;
  connection: anchor.web3.Connection;
  startDate: number;
  treasury: anchor.web3.PublicKey;
  txTimeout: number;
}

const Mint = (props: MintProps) => {
  const [isActive, setIsActive] = useState(false); // true when countdown completes
  const [isSoldOut, setIsSoldOut] = useState(false); // true when items remaining is zero
  const [isMinting, setIsMinting] = useState(false); // true when user got to press MINT

  const [alertState, setAlertState] = useState<AlertState>({
    open: false,
    message: "",
    severity: undefined,
  });

  const [startDate, setStartDate] = useState(new Date(props.startDate));

  const wallet = useWallet();
  const [candyMachine, setCandyMachine] = useState<CandyMachine>();

  const isAllowed =
    whitelist.includes(wallet.publicKey?.toString() as any) || true;

  const browserMintHistory = window.localStorage.getItem("mintHistory");
  const mintHistory = browserMintHistory ? JSON.parse(browserMintHistory) : [];

  const isUnderLimit = mintHistory.length < 2 || true;

  const onMint = async () => {
    try {
      setIsMinting(true);
      if (wallet.connected && candyMachine?.program && wallet.publicKey) {
        const mintTxId = await mintOneToken(
          candyMachine,
          props.config,
          wallet.publicKey,
          props.treasury
        );

        const status = await awaitTransactionSignatureConfirmation(
          mintTxId,
          props.txTimeout,
          props.connection,
          "singleGossip",
          false
        );

        if (!status?.err) {
          setAlertState({
            open: true,
            message: "Congratulations! Mint succeeded!",
            severity: "success",
          });
          const browserMintHistory =
            window?.localStorage?.getItem("mintHistory");
          const mintHistory = browserMintHistory
            ? JSON.parse(browserMintHistory)
            : [];
          mintHistory.push(mintTxId);
          window?.localStorage?.setItem(
            "mintHistory",
            JSON.stringify(mintHistory)
          );
        } else {
          setAlertState({
            open: true,
            message: "Mint failed! Please try again!",
            severity: "error",
          });
        }
      }
    } catch (error: any) {
      // TODO: blech:
      let message = error.msg || "Minting failed! Please try again!";
      if (!error.msg) {
        if (error.message.indexOf("0x138")) {
        } else if (error.message.indexOf("0x137")) {
          message = `SOLD OUT!`;
        } else if (error.message.indexOf("0x135")) {
          message = `Insufficient funds to mint. Please fund your wallet.`;
        }
      } else {
        if (error.code === 311) {
          message = `SOLD OUT!`;
          setIsSoldOut(true);
        } else if (error.code === 312) {
          message = `Minting period hasn't started yet.`;
        }
      }

      setAlertState({
        open: true,
        message,
        severity: "error",
      });
    } finally {
      setIsMinting(false);
    }
  };

  useEffect(() => {
    (async () => {
      if (
        !wallet ||
        !wallet.publicKey ||
        !wallet.signAllTransactions ||
        !wallet.signTransaction
      ) {
        return;
      }

      const anchorWallet = {
        publicKey: wallet.publicKey,
        signAllTransactions: wallet.signAllTransactions,
        signTransaction: wallet.signTransaction,
      } as anchor.Wallet;

      const { candyMachine, goLiveDate, itemsRemaining } =
        await getCandyMachineState(
          anchorWallet,
          props.candyMachineId,
          props.connection
        );

      setIsSoldOut(itemsRemaining === 0);
      //   setStartDate(goLiveDate);
      setCandyMachine(candyMachine);
    })();
  }, [wallet, props.candyMachineId, props.connection]);

  return (
    <>
      <MintContainer>
        <ConnectButton />

        <MintButton
          disabled={
            !wallet.connected ||
            isSoldOut ||
            isMinting ||
            !isActive ||
            !isAllowed ||
            !isUnderLimit
          }
          onClick={onMint}
          variant="contained"
        >
          {isSoldOut ? (
            "SOLD OUT"
          ) : isActive ? (
            isMinting ? (
              "MINTING..."
            ) : wallet.connected ? (
              !isAllowed ? (
                "CONNECT A WHITELISTED WALLET"
              ) : !isUnderLimit ? (
                "YOU HAVE MINTED THE MAXIMUM NFT"
              ) : (
                "MINT FOR 0.3 SOL"
              )
            ) : (
              "CONNECT YOUR WALLET TO MINT"
            )
          ) : (
            <Countdown
              daysInHours={true}
              date={startDate}
              onMount={({ completed }) => completed && setIsActive(true)}
              onComplete={() => setIsActive(true)}
              renderer={renderCounter}
            />
          )}
        </MintButton>
      </MintContainer>

      <Snackbar
        open={alertState.open}
        autoHideDuration={6000}
        onClose={() => setAlertState({ ...alertState, open: false })}
      >
        <Alert
          onClose={() => setAlertState({ ...alertState, open: false })}
          severity={alertState.severity}
        >
          {alertState.message}
        </Alert>
      </Snackbar>
    </>
  );
};

interface AlertState {
  open: boolean;
  message: string;
  severity: "success" | "info" | "warning" | "error" | undefined;
}

const renderCounter = ({ days, hours, minutes, seconds, completed }: any) => {
  return (
    <CounterText>
      Minting in: {days} days, {hours} hours, {minutes} minutes, {seconds}{" "}
      seconds
    </CounterText>
  );
};

export default Mint;
