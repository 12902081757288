import { useEffect, useState } from "react";
import styled from "styled-components";
import Countdown from "react-countdown";
import { Button, CircularProgress, Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import whitelist from "./whitelist.json";

import * as anchor from "@project-serum/anchor";

import { useWallet } from "@solana/wallet-adapter-react";
import { WalletMultiButton } from "@solana/wallet-adapter-material-ui";

import {
  CandyMachine,
  awaitTransactionSignatureConfirmation,
  getCandyMachineState,
  mintOneToken,
} from "./candy-machine";

import theme from "./theme";

export interface MintProps {
  candyMachineId: anchor.web3.PublicKey;
  config: anchor.web3.PublicKey;
  connection: anchor.web3.Connection;
  startDate: number;
  treasury: anchor.web3.PublicKey;
  txTimeout: number;
}

const Mint = (props: MintProps) => {
  const wallet = useWallet();

  const [cmState, setCmState] = useState<any>();

  const onRefresh = async () => {
    if (
      !wallet ||
      !wallet.publicKey ||
      !wallet.signAllTransactions ||
      !wallet.signTransaction
    ) {
      return;
    }

    const anchorWallet = {
      publicKey: wallet.publicKey,
      signAllTransactions: wallet.signAllTransactions,
      signTransaction: wallet.signTransaction,
    } as anchor.Wallet;

    const cm = await getCandyMachineState(
      anchorWallet,
      props.candyMachineId,
      props.connection
    );

    setCmState(cm);
  };

  console.log(cmState);

  return (
    <div>
      <WalletMultiButton />
      <button
        onClick={async () => {
          await onRefresh();
        }}
      >
        Refresh
      </button>
      {`Remaining: ${cmState?.itemsRemaining}, Available: ${cmState?.itemsAvailable}`}
    </div>
  );
};

export default Mint;
